import { SentryRoutes } from "./v1/utils/initSentry";
import { BrowserRouter, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import CssBaseline from "@mui/material/CssBaseline";

import { GlobalProvider } from "./v1/contexts/GlobalContext";

import LandingScreen from "./v1/components/LandingScreen";
import GlobalErrorBoundary from "./v1/components/UI/GlobalErrorBoundary";
import PaymentConfirmation from "./v1/components/PaymentLinks/PaymentConfirmation";
import PaymentExpired from "./v1/components/PaymentLinks/PaymentExpired";
import PaymentCompleted from "./v1/components/PaymentLinks/PaymentCompleted";
import StripeConfirmationPage from "./v1/components/Stripe/StripeConfirmationPage";

const App = () => {
  return (
    <GlobalProvider>
      <CssBaseline>
        <GlobalErrorBoundary>
          <BrowserRouter>
            <SentryRoutes>
              <Route path="/" element={<LandingScreen />} />
              <Route
                path="/stripe/confirmation/:intentId/:pubKey/:type"
                element={<StripeConfirmationPage />}
              />
              <Route path="/confirmation" element={<PaymentConfirmation />} />
              <Route path="/links/completed" element={<PaymentCompleted />} />
              <Route path="/links/expired" element={<PaymentExpired />} />
            </SentryRoutes>
          </BrowserRouter>
          <Toaster />
        </GlobalErrorBoundary>
      </CssBaseline>
    </GlobalProvider>
  );
};

export default App;
