import config from "../../config";

export const LANDING_PAGE = "https://xpaycheckout.com/";

const API_BASE_URL = config.api_path + "/";

export const XPAY_PAYMENT_URL = API_BASE_URL + "payments/";

export const XPAY_TRIGGER_INTENT_URL = XPAY_PAYMENT_URL + "trigger-intent";

export const XPAY_GET_INTENT_URL = XPAY_PAYMENT_URL + "get-intent/";

export const XPAY_STRIPE_COLLECT_URL = XPAY_PAYMENT_URL + "callback/";

export const INGEST_BILLING_ADDRESS_URL = XPAY_PAYMENT_URL + "ingest-billing-address";

export const XPAY_STRIPE_ERROR_URL = XPAY_PAYMENT_URL + "callback/";

