import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { PaymentStatus } from "../../constants/entities";
import StripeCheckoutForm from "./StripeCheckoutForm";
import PaymentStatusView from "../PaymentStatusView";
import { useGlobalContext } from "../../contexts/GlobalContext";
import StimulationCheckoutForm from "./StimulationCheckoutForm";
import LandingWrapper from "../UI/LandingWrapper";

const StripePaymentElement = () => {
  const { intent, setStatus } = useGlobalContext();

  if (!intent.pgPublicKey) {
    setStatus(PaymentStatus.ERROR);
    return <PaymentStatusView />;
  }

  const appearance = {
    theme: "stripe",
    labels: "floating",
  };

  const options = {
    clientSecret: intent.clientSecret,
    appearance: appearance,
  };

  const stripePromise = loadStripe(intent.pgPublicKey);

  const referrer = document.referrer;
  const stimulation =
    referrer === "https://demo-staging.xpaycheckout.com/" ||  // Staging Env
    referrer === "https://poc.xpaycheckout.com/" || // Staging Env
    referrer === "https://demo.xpaycheckout.com/" || // Prod Env
    referrer === "http://localhost:5173/"; // Dev Env
    
  return (
    <Elements stripe={stripePromise} options={options}>
      {!stimulation ? (
        <LandingWrapper>
          <StripeCheckoutForm />
        </LandingWrapper>
      ) : (
        <StimulationCheckoutForm />
      )}
    </Elements>
  );
};

export default StripePaymentElement;
