import { PaymentStatus } from "../constants/entities";
import CircularLoader from "./UI/CircularLoader";
import PaymentStatusSuccess from "./StatusScreens/PaymentStatusSuccess";
import PaymentStatusError from "./StatusScreens/PaymentStatusError";
import { useGlobalContext } from "../contexts/GlobalContext";

const PaymentStatusView = ({ customMessage }) => {
  const { status } = useGlobalContext();

  if (customMessage) {
    return (
      <div className="flex flex-col items-center justify-center gap-10 pt-10">
        <div>{customMessage} </div>
        <div className="success-circular-loader" />
      </div>
    );
  }

  switch (status) {
    case PaymentStatus.LOADING:
      return (
        <div className="flex items-center justify-center">
          <CircularLoader />
        </div>
      );
    case PaymentStatus.SUCCESS:
      return <PaymentStatusSuccess />;
    case PaymentStatus.ERROR:
    default:
      return <PaymentStatusError />;
  }
};

export default PaymentStatusView;
