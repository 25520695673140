import Loader from "../../UI/Loader";
import completedAnimation from "../../../assets/Animations/paymentCompleted.json";

// Used same as `PaymentCompleted` For REFUNDED Status as of now (might change in future)
const PaymentStatusRefunded = () => {
  return (
    <div className="">
      <Loader
        json={completedAnimation}
        width="600px"
        height="350px"
        loop={false}
        keepLastFrame={true}
        mobileHeight="300px"
        mobileWidth="350px"
      />
      <p className="text-lg text-center b-4 ">Your Payment is Completed</p>
    </div>
  );
};

export default PaymentStatusRefunded;
