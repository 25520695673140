export const currencyMap = [
    { 
        name: "US Dollar",
        code: "USD",
        symbol: "$",
        denomination: "REGULAR", 
    },
    { 
        name: "Pakistani Rupee",
        code: "PKR",
        symbol: "₨",
        denomination: "REGULAR", 
    },
    { 
        name: "Bangladeshi Taka",
        code: "BDT",
        symbol: "৳",
        denomination: "REGULAR", 
    },
    { 
        name: "Nepalese Rupee",
        code: "NPR",
        symbol: "रू",
        denomination: "REGULAR", 
    },
    { 
        name: "Great British Pound",
        code: "GBP",
        symbol: "£",
        denomination: "REGULAR", 
    },
    { 
        name: "Euro",
        code: "EUR",
        symbol: "€",
        denomination: "REGULAR", 
    },
    { 
        name: "Saudi riyal",
        code: "SAR",
        symbol: "ر.س",
        denomination: "REGULAR", 
    },
    { 
        name: "Australian Dollar",
        code: "AUD",
        symbol: "A$",
        denomination: "REGULAR", 
    },
    { 
        name: "Canadian Dollar",
        code: "CAD",
        symbol: "C$",
        denomination: "REGULAR", 
    },
    { 
        name: "UAE Dirham",
        code: "AED",
        symbol: "د.إ",
        denomination: "REGULAR", 
    },
    { 
        name: "Sri Lankan Rupee",
        code: "LKR",
        symbol: "රු",
        denomination: "REGULAR", 
    },
    { 
        name: "Vietnamese Dong",
        code: "VND",
        symbol: "₫",
        denomination: "ZERO_DECIMAL", 
    }
]