import Loader from "../UI/Loader";
import completedAnimation from "../../assets/Animations/paymentCompleted.json";
import LandingWrapper from "../UI/LandingWrapper";

const PaymentCompleted = () => {
  return (
    <LandingWrapper>
    <div className="">
      <Loader
        json={completedAnimation}
        width="600px"
        height="350px"
        loop={false}
        keepLastFrame={true}
        mobileHeight="300px"
        mobileWidth="350px"
      />
      <p className="text-lg text-center b-4 ">
        Your Payment is Completed
      </p>
    </div>
    </LandingWrapper>
  );
};

export default PaymentCompleted;
