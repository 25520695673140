import triggerIntent from "../apis/triggerIntent";
import { ErrorHandling } from "../utils/ErrorHandling";
import { useEffect } from "react";
import PaymentStatusView from "./PaymentStatusView";
import { PGTypes } from "../constants/entities";
import StripePaymentElement from "./Stripe/StripePaymentElement";
import { useGlobalContext } from "../contexts/GlobalContext";
import BackButton from "./UI/BackButton";
import DemoModeTag from "./UI/DemoModeTag";

const LandingScreen = () => {
  const { setStatus, intent, setIntent } = useGlobalContext();

  const init = async () => {
    try {
      const intent = await triggerIntent({ country: null });
      setIntent(intent);
    } catch (err) {
      ErrorHandling(err, setStatus);
    }
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderPaymentElement = () => {
    if (!intent) return <PaymentStatusView />;

    switch (intent.pg) {
      case PGTypes.STRIPE:
        return <StripePaymentElement />;

      default:
        return <PaymentStatusView />;
    }
  };

  return (
    <>
      <BackButton />
      {process.env.REACT_APP_XPAY_ENV !== "prod" && <DemoModeTag />}
      <>{renderPaymentElement()}</>
    </>
  );
};

export default LandingScreen;
