import { ErrorBoundary } from "@sentry/react";
import LandingWrapper from "./LandingWrapper";

const ErrorScreen = ({ error, componentStack, resetError }) => {
  const reloadWindow = () => {
    resetError();
    window.location.reload(false); // Force reload from server and ignore cached files
  };

  const errorMessage = error?.message ? error.message : "Unknown error";

  return (
    <LandingWrapper>
      <p>Something went wrong. Please try again.</p>
      <p>Error: {errorMessage}</p>
      <button onClick={reloadWindow} className="error-page-retry-btn">
        Try again
      </button>
    </LandingWrapper>
  );
};

const GlobalErrorBoundary = ({ children }) => {
  return <ErrorBoundary fallback={ErrorScreen}>{children}</ErrorBoundary>;
};

export default GlobalErrorBoundary;
