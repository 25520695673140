import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import initSentry from "./v1/utils/initSentry";
import { PostHogProvider} from 'posthog-js/react'
import config from "./config";

const options = {
  api_host: config.postHog_host,
}

initSentry(); // Initialize Sentry for error monitoring

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <PostHogProvider
    apiKey={config.postHog_key}
    options={options}
  >
    <App />
  </PostHogProvider>
);
