import { createContext, useContext, useState } from "react";
import { PaymentStatus } from "../constants/entities";

const GlobalContext = createContext({});

export const GlobalProvider = (props) => {
  const [status, setStatus] = useState(PaymentStatus.LOADING);
  const [intent, setIntent] = useState(null);

  return (
    <GlobalContext.Provider
      value={{
        status,
        setStatus,
        intent,
        setIntent,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => {
  return useContext(GlobalContext);
};
