import { useEffect } from "react";
import getIntentDetails from "../../../apis/getIntent";
import XPAY_QUERY_PARAMS from "../../../constants/xpayQueryParams";
import { ErrorHandling } from "../../../utils/ErrorHandling";
import { useGlobalContext } from "../../../contexts/GlobalContext";
import { PaymentStatus, XIntentStatus } from "../../../constants/entities";
import LandingWrapper from "../../UI/LandingWrapper";
import PaymentStatusSuccess from "./PaymentStatusSuccess";
import PaymentStatusFailed from "./PaymentStatusFailed";
import PaymentStatusRefunded from "./PaymentStatusRefunded";
import PaymentStatusView from './../../PaymentStatusView';

const PaymentConfirmationScreen = () => {
  const { status, setStatus } = useGlobalContext();

  const init = async () => {
    try {
      const intent = await getIntentDetails(getUrlParams());
      setStatus(intent.status);
    } catch (error) {
      ErrorHandling(error, setStatus);
    }
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderContent = () => {
    switch (status) {
      case XIntentStatus.SUCCESS:
        return <PaymentStatusSuccess />;
      case XIntentStatus.REFUNDED:
        return <PaymentStatusRefunded />;
      case XIntentStatus.FAILED:
      case XIntentStatus.INCOMPLETE:
      case XIntentStatus.CREATED:
        return <PaymentStatusFailed />;
      case PaymentStatus.LOADING: 
      case PaymentStatus.ERROR: 
      default: 
        return <PaymentStatusView />;
    }
  };

  return <LandingWrapper>{renderContent()}</LandingWrapper>;
};

const getUrlParams = () => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const intentId = params.get(XPAY_QUERY_PARAMS.INTENT_ID);
  const type = params.get(XPAY_QUERY_PARAMS.PAYMENT_TYPE);
  return { intentId, type };
};

export default PaymentConfirmationScreen;
