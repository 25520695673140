import axios from "axios";
import { XPAY_GET_INTENT_URL } from "../constants/config";

const getIntentDetails = async ({ intentId }) => {
  const url = XPAY_GET_INTENT_URL;
  const { data } = await axios.get(url + intentId);
  return data;
};

export default getIntentDetails;
