import { PaymentStatus } from "../../constants/entities";
import {
  useStripe,
  useElements,
  PaymentElement,
  AddressElement,
} from "@stripe/react-stripe-js";
import PaymentStatusView from "../PaymentStatusView";
import { useState } from "react";
import XPAY_QUERY_PARAMS from "../../constants/xpayQueryParams";
import collectStripeErrorData from "../../apis/collectStripeErrorData";
import { ErrorHandling } from "../../utils/ErrorHandling";
import axios from "axios";
import {
  INGEST_BILLING_ADDRESS_URL,
  LANDING_PAGE,
} from "../../constants/config";
import { currencyMap } from "../../constants/currencyMap";
import { useGlobalContext } from "../../contexts/GlobalContext";

const DefaultValuesOption = {
  // Don't ask for billing details from customer and prefill automatically
  billingDetails: {
    name: "never",
    email: "never",
    phone: "never",
  },
};

const StripeCheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();

  const { intent, status, setStatus } = useGlobalContext();
  const [address, setAddress] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [paymentElementEvent, setPaymentElementEvent] = useState(false);
  const [addressElementEvent, setAddressElementEvent] = useState(false);

  const isLoadComplete = Boolean(stripe && elements);
  const intentId = intent.xIntentId;

  const getConfirmationParams = (intent, intentId, address) => ({
    return_url: getReturnURL(intent, intentId),
    payment_method_data: {
      billing_details: {
        name: address?.name,
        email: intent.customerDetails?.email,
        phone: intent.customerDetails?.contactNumber,
        address: {
          city: address?.city,
          country: address?.country,
          line1: address?.addressLine1,
          line2: address?.addressLine2,
          postal_code: address?.postalCode,
          state: address?.state,
        },
      },
    },
  });

  const getReturnURL = (intent, intentId) => {
    return `${window.location.origin}/stripe/confirmation/${intentId}/${intent.pgPublicKey}/${intent.type}`;
  };

  const triggerRedirect = (intent, intentId, setStatus) => {
    try {
      if (!intent?.callbackUrl) {
        setStatus(PaymentStatus.ERROR);
        ErrorHandling(new Error("callbackUrl not found"), setStatus);
        setTimeout(() => window.location.replace("/"), 1000);
        return;
      }
      const callback = new URL(intent.callbackUrl);
      callback.searchParams.append(XPAY_QUERY_PARAMS.INTENT_ID, intentId);
      callback.searchParams.append(XPAY_QUERY_PARAMS.PAYMENT_TYPE, intent.type);
      setTimeout(() => window.location.replace(callback.href), 5000);
    } catch (error) {
      ErrorHandling(error, setStatus);
    }
  };

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded. Disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    // Address Data from Stripe Element
    const addressElement = elements.getElement(AddressElement);

    if (addressElement) {
      const result = await addressElement.getValue();

      if (result.complete) {
        setAddress(result.value);

        const sendAddress = {
          xIntentId: intentId,
          billingAddress: {
            addressLine1: result.value?.address?.line1,
            addressLine2: result.value?.address?.line2,
            city: result.value?.address?.city,
            state: result.value?.address?.state,
            country: result.value?.address?.country,
            postalCode: result.value?.address?.postal_code,
          },
        };

        try {
          await axios.post(INGEST_BILLING_ADDRESS_URL, sendAddress);
        } catch (error) {
          setStatus(PaymentStatus.ERROR);
          ErrorHandling(error, setStatus);
          return;
        }
      }
    }

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: getConfirmationParams(intent, intentId, address),
    });

    if (result.error) {
      // Show error messagge from Stripe (for example: card declined etc.)
      setStatus(PaymentStatus.ERROR);
      setErrorMessage(result.error.message);
      try {
        // Collect error data on backend
        await collectStripeErrorData({
          payload: result,
          type: intent.type,
          intentId,
        });
        // Manually trigger redirect to callback URL
        triggerRedirect(intent, intentId, setStatus);
      } catch (error) {
        ErrorHandling(error, setStatus);
      } finally {
        setIsLoading(false);
      }
    }

    setIsLoading(false);
  };

  if (isLoadComplete && status === PaymentStatus.LOADING) {
    DefaultValuesOption.billingDetails = {
      name: address?.name,
      email: address?.email,
      phone: address?.contactNumber,
    };

    const options = {
      defaultValues: DefaultValuesOption,
      business: {
        name: intent.merchantName,
      },
    };

    const btnText = `Pay ${
      currencyMap.find(({ code }) => code === intent.presentmentCurrency)
        ?.denomination === "ZERO_DECIMAL"
        ? intent.presentmentAmount
        : intent.presentmentAmount / 100
    } ${intent.presentmentCurrency}`;

    return (
      <div className="stack">
        <p className="pt-10 stripe-merchant-name">
          Paying to <b>{intent.merchantName}</b>
        </p>
        <div className="flex flex-col md:flex-row gap-10 md:gap-40 md:w-[70vw] md:min-h-[60vh] justify-center">
          <div className="transition-all">
            <div className="min-w-[30vw]">
              <PaymentElement
                options={options}
                onChange={(event) => {
                  setPaymentElementEvent(event.complete);
                }}
              />
              {intent?.collectInfo === "ADDRESS" && (
                <AddressElement
                  options={{
                    mode: "billing",
                    defaultValues: {
                      name: intent.customerDetails?.name,
                      address: {
                        line1:
                          intent.customerDetails?.billingAddress?.addressLine1,
                        line2:
                          intent.customerDetails?.billingAddress?.addressLine2,
                        city: intent.customerDetails?.billingAddress?.city,
                        state: intent.customerDetails?.billingAddress?.state,
                        postal_code:
                          intent.customerDetails?.billingAddress?.postalCode,
                        country:
                          intent.customerDetails?.billingAddress?.country,
                      },
                    },
                  }}
                  className="pt-4"
                  onChange={(event) => {
                    setAddress(event.value);
                    setAddressElementEvent(event.complete);
                  }}
                />
              )}
            </div>
            <button
              className="stripe-payment-btn"
              disabled={
                !paymentElementEvent ||
                (intent?.collectInfo === "ADDRESS" && !addressElementEvent)
              }
              onClick={handleSubmit}
            >
              {isLoading ? "Processing..." : btnText}
            </button>
            <div className="flex justify-center pt-10 align-center">
              Powered by{" "}
              <strong>
                &nbsp;
                <a href={LANDING_PAGE} target="_blank" rel="noreferrer">
                  {" "}
                  xPay{" "}
                </a>{" "}
                &nbsp;
              </strong>{" "}
              |{" "}
              <a href={LANDING_PAGE} target="_blank" rel="noreferrer">
                &nbsp; Terms
              </a>{" "}
              <a href={LANDING_PAGE} target="_blank" rel="noreferrer">
                &nbsp; Privacy
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <PaymentStatusView customMessage={errorMessage} />;
};

export default StripeCheckoutForm;
