import Loader from "../UI/Loader";
import expiredAnimation from "../../assets/Animations/paymentExpired.json";
import LandingWrapper from "../UI/LandingWrapper";

const PaymentExpired = () => {
  return (
    <LandingWrapper>
      <div className="pt-20">
        <Loader
          json={expiredAnimation}
          width="300px"
          height="150px"
          loop={false}
          keepLastFrame={true}
          mobileHeight="120px"
          mobileWidth="250px"
        />
        <p className="pt-20 mb-4 text-lg text-center ">
          Your Payment Link is Expired
        </p>
      </div>
    </LandingWrapper>
  );
};

export default PaymentExpired;
