import { Player } from "@lottiefiles/react-lottie-player";

const Loader = ({
  json = "https://lottie.host/2bddd68d-a3d2-4473-b25c-2394a4c8631c/MICVfnIufC.json",
  height = "500px",
  width = "500px",
  loop = true,
  keepLastFrame = true,
  mobileHeight = "150px",
  mobileWidth = "350px",
}) => {

    if (typeof window !== "undefined" && window.innerWidth < 768) {
      height = mobileHeight;
      width = mobileWidth;
    }


  return (
    <div className="">
      <div className="loaderOverlay">
        <Player
          src={json}
          background="transparent"
          speed="1"
          style={{ height: height, width: width }}
          loop={loop}
          autoplay
          keepLastFrame={keepLastFrame}
        />
      </div>
    </div>
  );
};

export default Loader;
