import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import {
  Routes,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

const initSentry = () => {
  const dsn = process.env.REACT_APP_SENTRY_DSN;
  const environment = process.env.REACT_APP_XPAY_ENV || "dev";
  if (!dsn) {
    return;
  }
  Sentry.init({
    dsn,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
    ],
    environment,
    tracesSampleRate: 1.0,
  });
};

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export default initSentry;
