import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { IoArrowBack } from "react-icons/io5";

const BackButton = () => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleBack = () => {
    if (window.history.length > 1) {
      window.history.back();
    } else {
      window.close();
    }
  };

  return (
    <div>
      {" "}
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Are you sure you want to quit?"}</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleBack}>Yes</Button>
        </DialogActions>
      </Dialog>
      <button
        className="flex items-center justify-center mx-4 my-2 text-gray-400 hover:opacity-50"
        onClick={handleClickOpen}
      >
        <IoArrowBack size={25} />
      </button>
    </div>
  );
};

export default BackButton;
