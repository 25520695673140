import { captureException } from "@sentry/react";
import { toast } from "react-hot-toast";
import { PaymentStatus } from "../constants/entities";

export const ErrorHandling = (error, setStatus) => {
  captureException(error);
  setStatus(PaymentStatus.ERROR);

  if (process.env.REACT_APP_XPAY_ENV === "prod") {
    toast.error("Something went wrong!");
  } else {
    toast.error(
      error?.response?.data?.errorDescription ||
        error.message ||
        "Something went wrong"
    );
    console.error(error);
  }
};
