import { useState } from "react";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import { PaymentStatus } from "../../constants/entities";
import { useEffect } from "react";
import LandingWrapper from "../UI/LandingWrapper";
import PaymentStatusView from "../PaymentStatusView";
import { loadStripe } from "@stripe/stripe-js";
import { useParams } from "react-router-dom";
import getIntentDetails from "../../apis/getIntent";
import collectStripeCallbackData from "../../apis/collectStripeCallbackData";
import XPAY_QUERY_PARAMS from "../../constants/xpayQueryParams";
import { ErrorHandling } from "../../utils/ErrorHandling";
import { useGlobalContext } from "../../contexts/GlobalContext";

const StripeConfirmationPage = () => {
  const stripe = useStripe();
  const { intentId, type } = useParams();
  const { setStatus } = useGlobalContext();
  const [errorMessage, setErrorMessage] = useState(null);

  const getPaymentStatus = async (stripeInstance, clientSecret) => {
    const { paymentIntent } = await stripeInstance.retrievePaymentIntent(
      clientSecret
    );
    if (!paymentIntent?.status) {
      setStatus(PaymentStatus.ERROR);
      return;
    }
    switch (paymentIntent.status) {
      case "succeeded":
        setStatus(PaymentStatus.SUCCESS);
        break;
      default:
        setStatus(PaymentStatus.ERROR);
        setErrorMessage("Your payment failed. Please try again.");
        break;
    }
    try {
      // Collect redirect data on backend
      await collectStripeCallbackData({ paymentIntent, type, intentId });
      // Redirect to Callback URL
      triggerRedirect();
    } catch (error) {
      ErrorHandling(error, setStatus);
    }
  };

  const triggerRedirect = async () => {
    try {
      const intent = await getIntentDetails({ intentId, type });
      if (!intent?.callbackUrl) {
        setStatus(PaymentStatus.ERROR);
        ErrorHandling(new Error("callbackUrl not found"), setStatus);
        return;
      }
      const callback = new URL(intent.callbackUrl);
      callback.searchParams.append(XPAY_QUERY_PARAMS.INTENT_ID, intentId);
      callback.searchParams.append(XPAY_QUERY_PARAMS.PAYMENT_TYPE, type);
      setTimeout(() => window.location.replace(callback.href), 5000);
    } catch (error) {
      ErrorHandling(error, setStatus);
    }
  };

  const getClientSecret = () => {
    const url = new URL(window.location.href);
    const clientSecret = url.searchParams.get("payment_intent_client_secret");
    return clientSecret;
  };

  useEffect(() => {
    if (!stripe) return;
    const clientSecret = getClientSecret();
    if (!clientSecret) {
      setStatus(PaymentStatus.ERROR);
      setErrorMessage("Your payment failed. Please try again.");
      return;
    }
    getPaymentStatus(stripe, clientSecret);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripe]);

  return (
    <LandingWrapper>
      <PaymentStatusView customMessage={errorMessage} />
    </LandingWrapper>
  );
};

const WrapperdStripeConfirmationPage = () => {
  const { pubKey } = useParams();
  const stripePromise = loadStripe(pubKey);

  return (
    <Elements stripe={stripePromise}>
      <StripeConfirmationPage />
    </Elements>
  );
};

export default WrapperdStripeConfirmationPage;
