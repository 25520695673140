import Loader from "../UI/Loader";
import { useEffect, useState } from "react";
import successAnimation from "../../assets/Animations/successAnimation.json";

const PaymentStatusSuccess = () => {
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowMessage(true);
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className="pt-8 payment-confirmation-cont stack">
      <Loader
        json={successAnimation}
        width="500px"
        height="250px"
        loop={false}
        keepLastFrame={true}
        mobileHeight="250px"
        mobileWidth="500px"
      />
      <div className="pt-8 mb-8 text-lg">Thank you for your payment!</div>
      {
        <p className={`${showMessage ? "success-loader" : "success-hide"} `}>
          Redirecting to your website...{" "}
          <div className="success-circular-loader" />
        </p>
      }
    </div>
  );
};

export default PaymentStatusSuccess;
