import axios from "axios";
import { XPAY_TRIGGER_INTENT_URL } from "../constants/config";
import XPAY_QUERY_PARAMS from "../constants/xpayQueryParams";
import { PaymentTypes } from "../constants/entities";

const triggerIntent = async ({ country }) => {
  const { intentId, country: originCountry } = getURLParams();
  // Ignore detected country if already specified in the URL
  const userCountry = originCountry || country;

  return triggerOneTimePayment({ intentId, country: userCountry });
};

const triggerOneTimePayment = async ({ intentId, country }) => {
  const { data } = await axios.post(XPAY_TRIGGER_INTENT_URL, {
    xIntentId: intentId,
    country,
  });
  return { ...data, type: PaymentTypes.ONE_TIME };
};

const getURLParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const intentId = urlParams.get(XPAY_QUERY_PARAMS.INTENT_ID);
  const country = urlParams.get(XPAY_QUERY_PARAMS.COUNTRY);
  const type = urlParams.get(XPAY_QUERY_PARAMS.PAYMENT_TYPE);

  if (!intentId) {
    throw new Error("Invalid URL Params");
  }
  return { intentId, country, type };
};

export default triggerIntent;
