export const PaymentStatus = {
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
  LOADING: "LOADING",
};

export const PGTypes = {
  STRIPE: "STRIPE",
};

export const PaymentTypes = {
  ONE_TIME: "one_time",
};

export const XIntentStatus = {
  SUCCESS: "SUCCESS",
  FAILED: "FAILED",
  CREATED: "CREATED",
  INCOMPLETE: "INCOMPLETE",
  REFUNDED: "REFUNDED",
};