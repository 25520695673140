import React from "react";
import { ReactComponent as XpayLogo } from "../../assets/xPayLogoWhite.svg";
import { LANDING_PAGE } from "../../constants/config";
import DemoModeTag from './DemoModeTag';

const LandingWrapper = ({ children }) => {
  return (
    <>
      {process.env.REACT_APP_XPAY_ENV !== "prod" && <DemoModeTag />}
      <div className="landing-cont">
        <div className="stack">
          <a href={LANDING_PAGE} target="_blank" rel="noreferrer">
            <XpayLogo className="xpay-logo" />
          </a>
          <p className="logo-subtext">Global Access, Simplified</p>
          {children}
        </div>
      </div>
    </>
  );
};

export default LandingWrapper;
